import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/download/app/sing',
    name: 'sing',
    component: () => import("@/views/download/app/sing/sing"),
  },
  {
    path: '/product/video/:code',
    name: 'product',
    component: () => import("@/views/product/video/video"),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
